export const experienceData = [
    {
        id: 1,
        company: 'Pronto Technology Inc.',
        jobtitle: 'Software Engineer',
        startYear: '2022',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Aubergine Solutions Pvt. Ltd.',
        jobtitle: 'Software Engineer',
        startYear: '2020',
        endYear: '2022'
    },
    {
        id: 3,
        company: 'Aubergine Solutions Pvt. Ltd.',
        jobtitle: 'Software Development Intern',
        startYear: '2019',
        endYear: '2020'
    },
]