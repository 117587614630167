import one from '../assets/svg/projects/one.svg'
import two from '../assets/svg/projects/two.svg'
import three from '../assets/svg/projects/three.svg'
import four from '../assets/svg/projects/four.svg'
import five from '../assets/svg/projects/five.svg'
import six from '../assets/svg/projects/six.svg'
import seven from '../assets/svg/projects/seven.svg'
import eight from '../assets/svg/projects/eight.svg'


export const projectsData = [
    {
        id: 1,
        projectName: 'Pronto - Automate and Manage the Ecosystem',
        projectDesc: 'It provides various features for company collaboration and manages the ecosystem.',
        tags: ['Python', 'Django', 'AWS'],
        code: '',
        demo: 'https://www.gopronto.io/',
        image: seven
    },
    {
        id: 2,
        projectName: 'Player Profile App',
        projectDesc: 'It is a mobile application for managing sport-related profiles of the rugby players and coaches of London which is centralized by rugby clubs.',
        tags: ['Python', 'Django', 'AWS', 'PostgreSQL'],
        code: '',
        demo: 'https://playerprofile.com',
        image: ''
    },
    {
        id: 3,
        projectName: 'Billing Automation',
        projectDesc: 'The web application of billing automation handling the billing of off-takers and payments of investors from getting data from IoT-based meters.',
        tags: ['Python', 'Django', 'React', 'AWS'],
        code: '',
        demo: '',
        image: two
    },
]


// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/