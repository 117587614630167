export const educationData = [
    {
        id: 1,
        institution: 'Friedrich Alexander University, Erlangen, Germany',
        course: 'M.Sc. Data Science',
        startYear: '2022',
        endYear: 'Present'
    },
    {
        id: 2,
        institution: 'Dharmsinh Desai University, Nadiad, India',
        course: 'B.Tech in Information Technology',
        startYear: '2016',
        endYear: '2020'
    },
    {
        id: 3,
        institution: 'Gujarat Higher Secondary Education Board',
        course: 'Higher Secondary Education',
        startYear: '2014',
        endYear: '2016'
    }
]